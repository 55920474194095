<template>
  <div class="sensitive-word-form" v-show="isLoad">
    <form-panel
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基础信息">

          <el-form-item v-if="this.form.id !== undefined" label="敏感词">
            <div v-text="form.sensitiveWord"></div>
          </el-form-item>
          <el-form-item v-else label="新增敏感词" :rules="sensitiveWordsRules" prop="sensitiveWords" :style="{ marginBottom: '20px' }">
            <v-textarea v-model="form.sensitiveWords" :maxlength="100000" :showCounter="false" />
            <label class="tips">备注：单条敏感词长度2~20位，支持中文/英文/数字，支持添加多条敏感词，以英文逗号隔开</label>
          </el-form-item>

          <el-form-item label="分类" :rules="[{ required: true, message: '请选择分类', trigger: 'change' }]" prop="type">
            <v-select v-model="form.type" :options="typeOps" />
          </el-form-item>

        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { queryDetailURL, createURL, updateURL, getTypeListURL } from './api'
import { Col2Block, Col2Detail } from '@/components/bussiness'

export default {
  name: 'SensitiveWordForm',
  components: {
    Col2Block,
    Col2Detail
  },
  data () {
    const validateName = (rule, value, callback) => {
      const _value = value.trim()
      if (_value === '') {
        callback(new Error('请填写敏感词'))
      } else {
        const sensitiveWordsList = _value.split(',')
        const _sensitiveWordsList = []
        sensitiveWordsList.forEach((item) => {
          const _item = item.trim()
          if (_item !== '') {
            _sensitiveWordsList.push(_item)
          }
        })
        if (_sensitiveWordsList.length < 1) {
          callback(new Error('请填写敏感词'))
          return
        }
        if (_sensitiveWordsList.length > 50) {
          callback(new Error('单次最多添加50条敏感词，超出请分次添加'))
        }
        const minName = _sensitiveWordsList.find((item) => {
          return item && item.length < 2
        })
        if (minName) {
          callback(new Error(`敏感词过短： ${minName}`))
          return
        }
        const maxName = _sensitiveWordsList.find((item) => {
          return item && item.length > 20
        })
        if (maxName) {
          callback(new Error(`敏感词过长： ${maxName}`))
          return
        }
        for (let i = 0; i < _sensitiveWordsList.length; i++) {
          const item = _sensitiveWordsList[i]
          if (_sensitiveWordsList.indexOf(item) !== i) {
            callback(new Error(`敏感词重复，无法添加： ${item}`))
            return
          }
        }
        callback()
      }
    }
    return {
      isLoad: false,
      submitConfig: {
        queryUrl: queryDetailURL,
        submitUrl: undefined,
        submitMethod: ''
      },
      typeOps: [],
      form: {
        id: undefined,
        sensitiveWord: '',
        sensitiveWords: '',
        type: undefined
      },
      sensitiveWordsRules: [{ required: true, validator: validateName,  trigger: 'blur' }]
    }
  },
  created () {
    this.getTypeList()
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.form.id = id
      this.$setBreadcrumb('编辑')
      this.submitConfig.submitUrl = updateURL
      this.submitConfig.submitMethod = 'PUT'
      this.$refs.formPanel.getData({
        id
      })
    } else {
      this.$setBreadcrumb('新增')
      this.submitConfig.submitUrl = createURL
      this.submitConfig.submitMethod = 'POST'
    }
    this.isLoad = true
  },
  methods: {
    async getTypeList () {
      const { status, data } = await this.$axios.get(getTypeListURL)
      if (status === 100) {
        if (data && data.length) {
          data.forEach((item) => {
            item['text'] = item.value
            item['value'] = item.code
          })
          data.unshift({
            text: '请选择',
            value: undefined
          })
          this.typeOps = data
        }
      }
    },
    update (data) {
      this.form.type = data.type
      this.form.sensitiveWord = data.sensitiveWord
    },
    submitBefore (data) {
      // 操作日志的数据对象参数
      let dataObject = ''
      let word = !!data.sensitiveWord ? data.sensitiveWord : data.sensitiveWords
      if (this.typeOps && this.typeOps.filter(item => item.value === data.type)) {
        dataObject = `${word}-${this.typeOps.filter(item => item.value === data.type)[0].text}`
      }
      
      if (this.form.id) {
        return {
          id: this.form.id,
          type: this.form.type,
          dataObject: dataObject
        }
      } else {
        const sensitiveWordsList = data.sensitiveWords.split(',')
        const _sensitiveWordsList = []
        sensitiveWordsList.forEach((item) => {
          const _item = item.trim()
          if (_item !== '') {
            _sensitiveWordsList.push(_item)
          }
        })
        return {
          sensitiveWords: _sensitiveWordsList,
          type: this.form.type,
          dataObject: dataObject
        }
      }
    }
  }
}
</script>

<style lang="scss">
.sensitive-word-form {
  .el-form-item__error {
    white-space: nowrap;
  }
}
</style>
<style lang="scss" scoped>
.sensitive-word-form {
  .tips {
    margin-left: 20px;
  }
}
</style>
