var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoad,
          expression: "isLoad",
        },
      ],
      staticClass: "sensitive-word-form",
    },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  this.form.id !== undefined
                    ? _c("el-form-item", { attrs: { label: "敏感词" } }, [
                        _c("div", {
                          domProps: {
                            textContent: _vm._s(_vm.form.sensitiveWord),
                          },
                        }),
                      ])
                    : _c(
                        "el-form-item",
                        {
                          style: { marginBottom: "20px" },
                          attrs: {
                            label: "新增敏感词",
                            rules: _vm.sensitiveWordsRules,
                            prop: "sensitiveWords",
                          },
                        },
                        [
                          _c("v-textarea", {
                            attrs: { maxlength: 100000, showCounter: false },
                            model: {
                              value: _vm.form.sensitiveWords,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "sensitiveWords", $$v)
                              },
                              expression: "form.sensitiveWords",
                            },
                          }),
                          _c("label", { staticClass: "tips" }, [
                            _vm._v(
                              "备注：单条敏感词长度2~20位，支持中文/英文/数字，支持添加多条敏感词，以英文逗号隔开"
                            ),
                          ]),
                        ],
                        1
                      ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "分类",
                        rules: [
                          {
                            required: true,
                            message: "请选择分类",
                            trigger: "change",
                          },
                        ],
                        prop: "type",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.typeOps },
                        model: {
                          value: _vm.form.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "type", $$v)
                          },
                          expression: "form.type",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }